<template>
    <div id="editor"></div>
</template>

<script>
import E from 'wangeditor'
import SaveButton from './custom/saveButton.js'
import {UploadImage} from '@/api/text.js'
export default {
    props:{
        value:{
                type:String,
                default:''
            },
        height:{
            type:String,
            default:'325'

        },
        placeholder:{
            type:String,
            default:'请输入内容'
        }
    },
    data(){

        return {
            editor: null
        }
    },
    watch:{
            value(val){
                if(val) {
                    
                    if(this.editor){

                        this.editor.txt.html(val)
                    }

                }
            }
        },
    mounted(){

        this.editor = new E('#editor')
         //点击保存顺序不能乱调整
         this.editor.config.saveFn = (value)=>{
          this.$emit('save',value)

        } 
        E.registerMenu('savebutton',SaveButton) 
        this.editor.config.menus = [
        'head',
        'bold',
        'underline',
        'italic',
        'indent',
    'lineHeight',
        'image',
        'savebutton'
       
    ]
     this.editor.config.showFullScreen = false
 
     this.editor.config.height=this.height
     this.editor.config.placeholder=this.placeholder

     //监听内容变化
     this.editor.config.onchange = (newHtml)=> {
     
       this.$emit('input',newHtml)
     }
 
     //自定义上传图片逻辑
     this.editor.config.customUploadImg = function (resultFiles, insertImgFn) {

       for(let file of resultFiles){
        let formData = new FormData()
        formData.append('file',file)
       UploadImage(formData).then((res) => {
        let {url} = res.data   
         insertImgFn(url) 
      });  
     } 
}

     this.editor.create()

    },
    beforeDestroy() {
    // 销毁编辑器
    this.editor.destroy()
    this.editor = null
  }
}
</script>
<style lang="less" scoped>
   
</style>