<template>
  <div class="course_detail">
    <Header />
    <div class="main">
      <div class="nav">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/course' }"
            >课程学习</el-breadcrumb-item
          >
          <el-breadcrumb-item>课程详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="video-box">
        <div class="video-left">
          <div class="video-play">
         
          
            <video-player
      
              class="video-player vjs-custom-skin vjs-big-play-centered"
              ref="videoPlayer"
              controls
              :playsinline="true"
              @ready="playerReadied"
              @loadeddata="onLoaded"
              @timeupdate="onPlayerTimeupdate($event)"
              @ended="playEnd"
              :options="playerOptions"
            >
            </video-player>

            <div v-if="videoLoading"  style="color:#fff;background-color: #000;position: absolute;border-radius: 6px;width: 100%;height: 100%;left: 0;top:0;text-align: center;">
            <img style="height: 100%;" src="../assets/img/video_loading.gif">
            </div>

           
          </div>
          <div class="course-title">
            <div class="title">{{ courseInfo.title }}</div>
            <div class="note-btn" @click="handleNote">
              <img src="../assets/img/note.png" />
              <div>笔记</div>
            </div>
            <div class="added-btn" v-if="courseInfo.collect">
              <i class="el-icon-check"></i>
              <div>已加入</div>
            </div>
            <div class="add-btn" v-else @click="handleJoin">
              <i class="el-icon-plus"></i>
              <div>加入学习</div>
            </div>
           
          </div>
          <div class="study-count">{{ courseInfo.count }}人已学习过</div>
          <div class="line"></div>
          <div class="course-info">
            <div class="tab">
              <div
                :class="index === tabActive ? 'item active' : 'item'"
                v-for="(item, index) in tabs"
                :key="index"
                @click="tabChange(index)"
              >
                {{ item }}
              </div>
            </div>
            <div class="content">
                <!--课程简介-->
              <div
                v-if="tabActive === 0"
                v-html="courseInfo.introduction"
              ></div>
              <!---课程笔记-->
              <div v-if="tabActive === 1">

                 <empty v-if="noteEmpty"/>
                 
                <div v-else class="note-item" v-for="(item,index) in videoNoteList" :key="index">
                  <div class="top">
                    <div class="user-info">
                      <img :src="item.head||require('../assets/img/user.png')" />
                      <div class="nickname">{{ item.userName }}</div>
                    </div>
                    <div class="time">更新于{{ item.updateTime }}</div>
                  </div>
                  <div class="note-content" v-html="'笔记：'+item.note"></div>
                </div>

                <div style="margin-top: 10px;" v-if="this.videoNoteList.length>0&&this.videoNoteList.length<this.noteTotal">
                <more :title="loadMoreNoteBtnText" icon="" @clickMore="loadeMoreNote"></more>
              </div> 

              </div>
            </div>
          </div>
        </div>
        <div class="video-right">
          <div class="video-list">
            <div class="top">
              <div class="label">目录</div>
              <div class="count">
                （{{ playIndex + 1 }}/{{ videoList.length }}）
              </div>
            </div>
            <div class="video-panel">
              <div
                :class="
                  playIndex === index ? 'video-item active' : 'video-item'
                "
                v-for="(item, index) in videoList"
                :key="index"
                @click="changeVideo(index)"
              >
                <div class="title">
                  <img
                    src="../assets/img/current_play.png"
                    v-if="playIndex === index"
                  />
                  &nbsp;P{{(index+1)}}&nbsp;&nbsp;{{ item.title }}
                </div>
                <div class="time">{{ item.viewTime }}</div>
              </div>
            </div>
          </div>
          <div class="recommend">
            <div v-for="(item,index) in recommendCourseList" :key="index" style="margin-top: 16px;">
            <course-item direction="horizontal" :item="item" />
          </div>
          
           <div style="margin-top: 16px;" v-if="recommendCourseTotal>5">
            <more @clickMore="$router.push('/course')"></more>
           </div>
          </div>
        </div>
      </div>
      <!--记笔记dialog-->       
<el-dialog
  v-dialogDrag
  custom-class="drag-dialog"
  title="笔记"
  :visible.sync="showNoteDialog"
   :modal="false"
   width="355px"
   :close-on-click-modal="false">
   <template slot="title">
     <div style="font-size: 15px;display: flex;align-items: center;">
        <img style="width: 20px;height: 22px;vertical-align: middle;" src="../assets/img/note.png">
        记笔记
     </div>
   </template>
   <div>
   <WangEditorV4 v-model="videoNoteText" height="325" @save="saveNote" placeholder="请输入笔记"></WangEditorV4> 
   </div>
</el-dialog>
    </div>
<Footer/>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import CourseItem from "../components/CourseItem.vue";
import WangEditorV4 from '../components/WangEditorV4'
import Empty from '@/components/Empty'
import More from "../components/More.vue";
import { videoPlayer } from "vue-video-player";
import 'vue-video-player/src/custom-theme.css'
import "video.js/dist/video-js.css";
import { GetCourseList,GetCourseDetail, GetVideoList,SaveVideoNote,GetVideoNote,GetVideoNoteList,JoinOrDelCourse,SaveFinishedLearnVideo, SaveCourseTotalTime,SaveCurrentVideoTime } from "../api/list";
 

export default {
  components: {
    Header,
    WangEditorV4,
    CourseItem,
    Footer,
    videoPlayer,
    Empty,
    More
  },
  data() {
    return {
      tabs: ["课程详情", "课程笔记"],
      tabActive: 0,
      courseInfo:{
        collect: false
      },
      startTime:'',
      videoList: [],
      videoLoading:false,
      playIndex: -1,
      currentVideoTime:0,
      videoSrc: "",
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], 
        autoplay: false,
        muted:false,
        preload: "auto",
        language: "zh-CN",
        aspectRatio: "16:9",
        fluid: true,
        sources: [
          {
            type: "video/mp4", // 类型   type: "application/x-mpegURL"直播流
            src: '',
          },
        ],
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        poster: "",
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
      recommendCourseList:[],
      recommendCourseTotal:1,
      showNoteDialog:false,
      saveNoteBtnLoading:false,
      videoNoteList:[],
      videoNoteText:'',
      loadMoreNoteBtnText:'点击加载更多',
      noteTotal:1,
      notePage:1,
      notePageSize:10,
      noteEmpty:false,
      joinCourseBtnLoading:false,

    }
  },
  watch:{
    $route(){
      this.getCourseDetailData();
    this.getCourseVideoList();
    this.getRecommendCourseData();
    }
  },
  created() {
    this.getCourseDetailData();
    this.getCourseVideoList();
    this.getRecommendCourseData();
 
     let d = new Date();
     this.startTime = `${d.getFullYear()}-${
       d.getMonth() + 1
     }-${d.getDate()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
  },
 
  methods: {
    //加载课程详情
    getCourseDetailData() {
      GetCourseDetail(this.$route.params.id).then((res) => {
        this.courseInfo = res.data;
        this.playerOptions.poster = this.courseInfo.imageUrl;
      });
    },
    //加载视频笔记列表
    getVideoNoteListData(){

        GetVideoNoteList(this.videoList[this.playIndex].id,this.notePage,this.notePageSize).then(res=>{

            this.noteTotal = res.total;
            this.videoNoteList= this.videoNoteList.concat(res.data);
            this.noteEmpty = this.videoNoteList.length===0;
            this.loadMoreNoteBtnText='点击加载更多'
        }) 

    },
    //查看更多笔记
    loadeMoreNote(){

       if(this.videoNoteList.length<this.noteTotal){

          this.notePage++
       } 

       if( this.loadMoreNoteBtnText=='加载中...'){
        return;
       }

       this.loadMoreNoteBtnText='加载中...'

       this.getVideoNoteListData();
      

    },
    //加载课程视频列表
    getCourseVideoList() {
      GetVideoList(this.$route.params.id).then((res) => {
        this.videoList = res.data;
        if (this.videoList.length > 0) {
          this.changeVideo(0);
        }
      });
    },
    //获取推荐课程
    getRecommendCourseData(){

        GetCourseList(1,5,'','','1').then(res=>{

            this.recommendCourseTotal= res.total;
            this.recommendCourseList=res.data;

        })

    },
    //切换视频
    changeVideo(index) {
      if (this.playIndex == index) {
        return;
      } 

      //保存上一个视频进度
      if(this.currentVideoTime>0){
         this.videoList[this.playIndex].learnSchedule = this.currentVideoTime;
         this.saveCurrentVideoTime();
      }
      this.videoLoading=true;
      this.playIndex = index;
      let video = this.videoList[index];
      this.videoSrc = video.url;
      this.playerOptions.autoplay=true;
      this.$set(this.playerOptions.sources,0,{
        type:'video/mp4',
        src: this.videoList[index].url
      })
   
      this.currentVideoTime = video.learnSchedule||0

      this.videoNoteList=[]
      this.notePage=1;
      this.getVideoNoteListData();
 
 
    },

    playerReadied(player){
   
 
    },
    
    //视频预加载完成
    onLoaded(player){
      this.videoLoading=false;
      
       //记忆播放
      player.currentTime(this.currentVideoTime>=player.cache_.duration?0:this.currentVideoTime);
     
    },
    //监听播放时长
    onPlayerTimeupdate(player){
     
      this.currentVideoTime = player.cache_.currentTime;
 
    },
    //视频播放结束
    playEnd() {
       //保存完成播放的视频记录
       SaveFinishedLearnVideo({courseId:this.$route.params.id,videoId:this.videoList[this.playIndex].id})
      
      if (this.playIndex < this.videoList.length - 1) {
        let idx = this.playIndex;
        idx++;
        this.changeVideo(idx);
      }
    },
    //切换tab 
    tabChange(index) {
      if (this.tabActive === index) {
        return;
      }
      this.tabActive = index;
    },
    //显示记笔记
    handleNote(){

         if(this.videoList.length>0){

          GetVideoNote(this.videoList[this.playIndex].id).then(res=>{
            this.videoNoteText = res.data.note;
          })
         }
      

        this.showNoteDialog=true;
    },
    //保存笔记
    saveNote(value){

        if(value.length===0){
            this.$message.error('请输入笔记内容')
            return;
        }
        //防重复
        if(this.saveNoteBtnLoading){
            return;
        }
        this.saveNoteBtnLoading=true;
        SaveVideoNote({videoId:this.videoList[this.playIndex].id,note:value}).then(()=>{
            this.$message.success('保存笔记成功')
            this.showNoteDialog=false;
            this.videoNoteList=[];
            this.notePage=1;
            this.getVideoNoteListData();
        }).finally(()=>{
            this.saveNoteBtnLoading=false;
        })
    },
    //加入学习
    handleJoin(){
   
        if(this.joinCourseBtnLoading){
            return;
        }
        this.joinCourseBtnLoading=true;
        JoinOrDelCourse({courseId:this.$route.params.id}).then(()=>{
            this.$message.success('已加入课程学习')
            this.courseInfo.collect=true;

        }).finally(()=>{
            this.joinCourseBtnLoading=false;
        })

    },
     //保存当前视频播放进度
     saveCurrentVideoTime(){
      SaveCurrentVideoTime({courseId:this.$route.params.id,videoId:this.videoList[this.playIndex].id,learnSchedule:parseInt(this.currentVideoTime)})
     },
  },
    
     //保存课程学习时长
     beforeDestroy() {
      this.saveCurrentVideoTime();
    let d = new Date();
    let endTime = `${d.getFullYear()}-${
      d.getMonth() + 1
    }-${d.getDate()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
    SaveCourseTotalTime({courseId:this.$route.params.id,startTime:this.startTime,endTime:endTime}).then(res=>{})
  },
};
</script>

<style lang="less"  scoped>
@import url(../assets/css/Course_detail.css);


.course_detail{
    /deep/ .el-dialog{

        left:427px;
        top: -13px;

    }
  /deep/ .el-dialog__body{

    padding: 20px 10px;
  }  
}
 
.video-player {
    position: relative;
    border-radius: 6px;
    object-fit: fill;
  /deep/ .video-js{
    border-radius: 6px;
  }
  /deep/ .video-js .vjs-tech {
    border-radius: 6px;
  }
  /deep/ .video-js .vjs-poster {
    border-radius: 6px;
  }
 
}
</style>
