<template>
    <div class="custom-more" @click="clickMore">
    <div>{{ title }}<i v-if="icon" :class="icon"></i></div>
   </div>
</template>

<script>
export default {
    
    props:{
        title:{
            type:String,
            default:'查看更多'
        },
        icon:{
            type:String,
            default:'el-icon-arrow-right'
        }
    },
    methods:{
        //点击
        clickMore(){
            this.$emit('clickMore')
        }
    }

}
</script>

<style lang="less" scoped>
    .custom-more{
        background-color: #F3F4F6;
        border-radius: 6px;
        padding: 10px;
        font-size: 15px;
        text-align: center;
        cursor: pointer;
    }

</style>