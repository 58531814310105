import E from 'wangeditor' 

const {BtnMenu } = E
 

export default class SaveButton extends BtnMenu{


     constructor(editor){

        const $elem = E.$(
            `<div class="w-e-menu" data-title="保存">
              <div>保存</div>
            </div>`
        )

        super($elem,editor)

     }

     clickHandler(){

         //点击保存按钮 
        if(this.editor.config.saveFn){
            
            this.editor.config.saveFn(this.editor.txt.html())
        }
         

     }
     tryChangeActive() {

        this.active()
     }

}